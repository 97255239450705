import clock from '../img/advantages/clock.png'
import helper from '../img/advantages/helper.png'
import road from '../img/advantages/road.png'
import tickets from '../img/advantages/tickets.png'


function Advantages() {

    return(
        <div className='container advantages-container'>
            <div className='adv-item'>
                <img className='adv-img' src={clock} alt="" />
                <h3>No cashiers and no queues</h3>
                <p>Tickets online anytime</p>
            </div>
            <div className='adv-item'>
                <img className='adv-img' src={road} alt="" />
                <h3>Destinations throughout Europe</h3>
                <p>Rating of carrier flights based on passenger reviews</p>
            </div>
            <div className='adv-item'>
                <img className='adv-img' src={helper} alt="" />
                <h3>24/7 Support</h3>
                <p>Quick response in messengers at any time of the day</p>
            </div>
            <div className='adv-item'>
                <img className='adv-img' src={tickets} alt="" />
                <h3>Refund of tickets</h3>
                <p>Quick return through the administrator</p>
            </div>
        </div>
    )

}
export default Advantages;