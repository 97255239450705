import React, { useState } from 'react';

const ImageSlider = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [touchStartX, setTouchStartX] = useState(0);
  const totalImages = images.length;

  const handlePrevSlide = () => {
    setCurrentImageIndex(prevIndex => (prevIndex - 1 + totalImages) % totalImages);
  };

  const handleNextSlide = () => {
    setCurrentImageIndex(prevIndex => (prevIndex + 1) % totalImages);
  };

  const handleTouchStart = (event) => {
    setTouchStartX(event.touches[0].clientX);
  };

  const handleTouchEnd = (event) => {
    const touchEndX = event.changedTouches[0].clientX;
    const touchDifference = touchStartX - touchEndX;

    if (touchDifference > 0 && touchDifference > 100) {
      handleNextSlide();
    } else if (touchDifference < 0 && touchDifference < -100) {
      handlePrevSlide();
    }
  };

  return (
    <div
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      style={{ touchAction: 'pan-y' }}
      className='container header-menu'
    >
      <button className='red-arrow' onClick={handlePrevSlide} disabled={currentImageIndex === 0}>
      ❮
      </button>
      <img src={images[currentImageIndex]} alt="Slider" />
      <button className='red-arrow' onClick={handleNextSlide} disabled={currentImageIndex === images.length - 1}>
      ❯
      </button>
    </div>
  );
};

export default ImageSlider;
