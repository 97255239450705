import './App.css';
import MainScreen from './modules/MainScreen';
import Advantages from './modules/Advantages';
import Minutes from './modules/Minutes';
import AboutUs from './modules/AboutUs';
import Bottom from './modules/Bottom';


function App() {
  return (
    <div className="App">
      <MainScreen />
      <Advantages />
      <Minutes />
      <AboutUs />
      <Bottom />
    </div>
  );
}

export default App;
