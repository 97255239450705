import React, { useState } from "react";
import closeBtn from "../img/cross.png";



function Bottom() {  

  const [politics, setPolitics] = useState(false);
  const [returns, setReturns] = useState(false);
  const [usage, setUsage] = useState(false);
    return (
      <div className='footer'>
        {
          politics
          ? <div className="checkout-bg">
          <div className="checkout-modal success-modal">
            <h4 >Правила повернення:</h4>
            <p className="politics-text">1. Обов'язково обирати коректну причину повернення; у разі вказівки некоректної причини у поверненні може бути відмовлено.<br />

                2. При підтвердженні повернення квиток буде анульовано автоматично; скасувати повернення неможливо.<br />

                3. Сума до повернення розраховується автоматично за умовами Перевізника, що діють на момент оформлення повернення.<br />

                4. Повернення проводиться з цього приводу покупця. Повернення коштів на іншу картку (рахунок) або в інший спосіб неможливе.<br />

                5. Гроші надійдуть протягом 1-10 діб з моменту позитивного розгляду заявки на повернення.
                Вашу заявку буде розглянуто протягом 1-10 днів
                Після підтвердження повернення кошти будуть автоматично повернуті на карту, з якою відбувалася оплата квитків.</p>
          <img className="close-btn" src={closeBtn} onClick={() => {setPolitics(false)}} alt="" />
          </div>
        </div>
          :null
        }
        {
          returns
          ? <div className="checkout-bg">
          <div className="checkout-modal success-modal">
            <h4 >ПРАВИЛА ВИКОРИСТАННЯ САЙТУ Luckybus.online</h4>
            <p className="politics-text">Дані правила є невід'ємною частиною Договору публічної оферти та поширюються на всіх відвідувачів та користувачів сайту Luckybus.online як справжніх, так і майбутніх. Користуючись Сайтом або матеріалами сайту, Користувач висловлює свою безумовну згоду з цими Правилами. Примітка щодо використання цього сайту дітьми. Цей сайт не призначений для дітей, тому просимо, щоб діти не передавали та/або залишали особисту інформацію на цьому сайті.</p>
            <h4 >МЕТА ПРАВИЛ</h4>
            <p className="politics-text">Правила створені для визначення меж, дотримання яких необхідне для використання Сайту. Правила сформульовані з урахуванням чинного законодавства України.</p>
            <h4 >ЗАГАЛЬНІ ПОЛОЖЕННЯ</h4>
            <p className="politics-text">LuckyBus визначає правила поведінки на сайті та залишає за собою право вимагати їх виконання від Користувачів. Незнання правил не звільняє від необхідності виконання.</p>
            <h4 >ТОЧНІСТЬ ІНФОРМАЦІЇ.</h4>
            <p className="politics-text">LuckyBus має намір докладати всіх зусиль, щоб забезпечити точність та актуальність даних, отриманих від відповідного Постачальника, що містяться на цьому сайті. У представленій інформації на даному сайті можуть зустрітися технічні неточності або друкарські помилки. LuckyBus не бере на себе жодної відповідальності за точність інформації, наданої на сайті, і весь ризик використання такої інформації покладається на користувача.</p>
            <h4 >ЗМІНА УМОВ.</h4>
            <p className="politics-text">LuckyBus час від часу може вносити зміни до Правил користування Сайтом. Переглядаючи цей Сайт, Користувач зобов'язаний дотримуватись цих Правил, тому Користувач зобов'язаний перевіряти їх зміст при кожному новому відвідуванні сайту. LuckyBus залишає за собою право змінювати дані про послуги та продукцію в будь-який час без попереднього повідомлення користувача.</p>
            
          <img className="close-btn" src={closeBtn} onClick={() => {setReturns(false)}} alt="" />
          </div>
        </div>
          :null
        }
        {
          usage
          ? <div className="checkout-bg">
          <div className="checkout-modal success-modal">
            <h4 >ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ ЗАХИСТ ІНФОРМАЦІЇ</h4>
            <p className="politics-text">LuckyBus несе відповідальність за обробку отриманих персональних даних відповідно до вимог нормативно-правових документів і покладає відповідні аналогічні зобов'язання на будь-яких контрагентів, з якими він взаємодіє в процесі надання послуг або в процесі обробки отриманої інформації. LuckyBus дотримується основних принципів захисту конфіденційної інформації щодо будь-якої подальшої передачі даних як усередині країни, так і за кордоном, у тому числі щодо подальшої передачі зобов’язань. LuckyBus використовує протокол шифрування SSL 3.0 для забезпечення безпечної передачі персональних даних. Усі особисті дані зашифровані та безпечно передаються через Інтернет.</p>
            <h4 >ПЕРЕДАЧА ДАНИХ ПЕРЕВІЗНИКУ/ПОСТАЧАЛЬНИКУ</h4>
            <p className="politics-text">У своїй діяльності сервіс LuckyBus співпрацює безпосередньо з перевізниками або постачальниками квитків. Для надання якісних транспортних послуг Ваші персональні дані обов’язково передаються безпосередньо Перевізнику/Постачальнику на рейс, на який Ви придбали певний квиток. Основною метою передачі персональних даних Перевізнику/Постачальнику є укладення договору перевезення.</p>
            <h4>ОНОВЛЕННЯ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ</h4>
            <p className="politics-text">LuckyBus залишає за собою право вносити зміни в Політику конфіденційності. Якщо LuckyBus суттєво змінить Політику конфіденційності, це буде зазначено тут.</p>
          <img className="close-btn" src={closeBtn} onClick={() => {setUsage(false)}} alt="" />
          </div>
        </div>
          :null
        }
        <div className="footer-inner container">
          <div>Shevchenko st., 162a, Chernigov <br /> luckybusinfo@GMAIL.COM</div>
          <div className="policy-links">
            <p className="footer-link" onClick={() => {
              setPolitics(true)
              setReturns(false)
              setUsage(false)
            }}>Політика повернення</p>
            <p className="footer-link"  onClick={() => {
              setPolitics(false)
              setReturns(true)
              setUsage(false)
            }}>Умови Використання</p>
            <p className="footer-link" onClick={() => {
              setPolitics(false)
              setReturns(false)
              setUsage(true)
            }}>Політика Конфіденційності</p>
          </div>
        </div>
        <p className="copyrights">© 2022, LUCKYBUS. ONLINE TICKETING SERVICE. ALL RIGHTS RESERVED</p>
      </div>
    );
  }
  
  export default Bottom;