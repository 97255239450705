function Minutes() {

    return(
        <div className='container blue-bg'>
            <h1>Buy a bus ticket in 5 minutes</h1>
            <p>We adhere to a flexible policy of cooperation with customers and provide an opportunity to pay for bus tickets in a convenient way: in cash or make online payment using a bank card. If you have a question about how to buy/order/book or you just want to clarify what the ticket price is, write at any time.</p>
            <div className="minutes-btn">Shop safely with LUCKYBUS</div>
        </div>
    )

}
export default Minutes;