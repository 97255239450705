import React from "react";
import ImageSlider from "./ImageSlider";
import bus1 from "../img/SlidesBus/bus1.jpg"
import bus2 from "../img/SlidesBus/bus2.jpg"
import bus3 from "../img/SlidesBus/bus3.jpg"
import bus4 from "../img/SlidesBus/bus4.jpg"
import bus5 from "../img/SlidesBus/bus5.jpg"


function AboutUs() {
  const images = [bus1, bus2, bus3, bus4, bus5];

  return (
    <div className='container'>
        <p className="about-text">Our company works with carriers that provide bus transportation of tourists across Ukraine and abroad. Our task is to find potential customers (passengers) directly or through travel companies. We pass on the information about the captured passengers to the carriers, for which we receive a reward in the form of a percentage.</p>
        <ImageSlider images={images} />
    </div>
  );
}

export default AboutUs;